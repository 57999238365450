<template>
    <ol class="menu-breadcrumbs" :class="[margin, styling]">
        <li v-for="(layer, key) in layers" :key="key">
            <NuxtLink no-prefetch v-if="layer.path" :to="layer.path" :class="textColor" rel="follow">
                <span>{{ layer.title }}</span>
            </NuxtLink>
            <p v-else class="line-clamp-1 cursor-default" :class="textColor">
                {{ layer.title }}
            </p>
        </li>
    </ol>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'MicroBreadcrumbs',
    props: {
        layers: {
            type: Array as PropType<{ path?: string; title: string }[]>,
            required: true,
        },
        textColor: {
            type: String,
        },
        margin: {
            type: String,
        },
        styling: {
            type: String,
            default: 'flex',
        },
    },
})
</script>

<style lang="postcss" scoped>
.menu-breadcrumbs {
    @apply container relative z-10 h-8 space-x-4 px-2;
    li {
        @apply relative text-base leading-8 text-gray-800;
    }
    a {
        @apply line-clamp-1 lg:hover:underline;
    }
    li + li::before {
        @apply absolute -left-3;
        content: '/';
    }
}
</style>
